// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-fellowship-js": () => import("../src/templates/fellowship.js" /* webpackChunkName: "component---src-templates-fellowship-js" */),
  "component---src-templates-event-js": () => import("../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-articles-js": () => import("../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

